import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchWithToken from "../../utils/api";
import SuccessToast from "../../components/modals/successToast";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Back.svg";
import FailToast from "../../components/modals/failToast";

const DepartmentDetails = () => {
    const param = useParams();
    const DepartmentId = param?.id;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [fetchedData, setFetchedData] = useState<any>();
    const [positions, setPositions] = useState<any[]>([]);
    const [selectedPosition, setSelectedPosition] = useState<any | null>(null);
    const [selectedLevel, setSelectedLevel] = useState<any | null>(null);
    const [selectedSubDepartment, setSelectedSubDepartment] = useState<any | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [currentLimit, setCurrentLimit] = useState<string>("");
    const [limitUpdated, setLimitUpdated] = useState<boolean>(false);
    const [currentLimitValue, setCurrentLimitValue] = useState<string>("");
    const [currentMinLimitValue, setCurrentMinLimitValue] = useState<string>("");

    const MIN_MONTHS = 0;
    const MAX_MONTHS = 12;

    const GetSelectedDepartment = async () => {
        try {
            const data = await fetchWithToken(`/departments/${DepartmentId}`, {
                method: "GET",
            });
            setFetchedData(data?.data);
        } catch (error) {
            console.error("Failed to fetch shifts:", error);
        }
    };

    const fetchPositions = async () => {
        try {
            const data = await fetchWithToken(`/positions`, {
                method: "GET",
            });
            setPositions(data?.data || []);
        } catch (error) {
            console.error("Failed to fetch positions:", error);
        }
    };

    useEffect(() => {
        GetSelectedDepartment();
        fetchPositions();
    }, []);

    const handleChooseSubDepartment = (subDepartment: any) => {
        setSelectedSubDepartment(subDepartment);

        if (!subDepartment.months_limit) {
            subDepartment.months_limit = [];
        }

        setCurrentStep(2);
    };

    const handleChoosePosition = (position: any) => {
        setSelectedPosition(position);
        setSelectedLevel(null);
        setCurrentStep(3);
    };

    const handleChooseLevel = (level: any) => {
        setSelectedLevel(level);
        setCurrentStep(4);
    };


    const handleInputChange = (value: string) => {
        let debounceTimeout;
        setLimitUpdated(false)
        if (/^\d*$/.test(value)) {
            const numericValue = parseInt(value, 10);
            setCurrentLimit(value);
            if (numericValue >= MIN_MONTHS && numericValue <= MAX_MONTHS) {
                clearTimeout(debounceTimeout);

                debounceTimeout = setTimeout(async () => {
                    try {
                        const response = await fetchWithToken(`/departments/${DepartmentId}/months-limit`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                sub_department_id: selectedSubDepartment?._id,
                                position_id: selectedPosition?.id,
                                level_id: selectedLevel?._id,
                                number_of_months: value,
                            }),
                        });

                        setCurrentLimit(value);

                        setLimitUpdated(true);

                    } catch (error) {
                        console.error(error);
                        setLimitUpdated(false);
                    }
                }, 1000);
            }
        }
    };


    const handleInputBlur = () => {
        const numValue = parseInt(currentLimit, 10);
        if (isNaN(numValue) || numValue < MIN_MONTHS) {
            setCurrentLimit(MIN_MONTHS.toString());
        } else if (numValue > MAX_MONTHS) {
            setCurrentLimit(MAX_MONTHS.toString());
        }

        if (selectedSubDepartment && selectedPosition && selectedLevel) {
            // Create a deep copy of fetchedData to avoid mutating state directly
            setFetchedData((prevData: any) => {
                const updatedData = { ...prevData };

                const subDepartmentIndex = updatedData.sub_departments.findIndex(
                    (subDept: any) => subDept._id === selectedSubDepartment._id
                );

                if (subDepartmentIndex !== -1) {
                    const updatedSubDepartment = { ...updatedData.sub_departments[subDepartmentIndex] };

                    const positionIndex = updatedSubDepartment.months_limit.findIndex(
                        (limit: any) => limit.position_id === selectedPosition.id
                    );

                    if (positionIndex !== -1) {
                        const updatedPosition = { ...updatedSubDepartment.months_limit[positionIndex] };

                        const levelIndex = updatedPosition.levels.findIndex(
                            (levelObj: any) => levelObj.level_id === selectedLevel._id
                        );

                        if (levelIndex !== -1) {
                            updatedPosition.levels[levelIndex].number_of_months = numValue;
                        } else {
                            updatedPosition.levels.push({
                                level_id: selectedLevel._id,
                                number_of_months: numValue,
                            });
                        }

                        updatedSubDepartment.months_limit[positionIndex] = updatedPosition;
                    } else {
                        updatedSubDepartment.months_limit.push({
                            position_id: selectedPosition.id,
                            levels: [
                                {
                                    level_id: selectedLevel._id,
                                    number_of_months: numValue,
                                },
                            ],
                        });
                    }

                    updatedData.sub_departments[subDepartmentIndex] = updatedSubDepartment;
                }

                return updatedData;
            });
        }
    };




    const getSavedLimit = () => {
        if (selectedPosition && selectedLevel && selectedSubDepartment) {
            const existingPosition = selectedSubDepartment.months_limit.find(
                (p: any) => p.position_id === selectedPosition.id
            );

            if (existingPosition) {
                const existingLevel = existingPosition.levels.find(
                    (l: any) => l.level_id === selectedLevel._id
                );

                return existingLevel ? existingLevel.number_of_months : "";
            }
        }
        return "";
    };

    const getSavedMinLimit = () => {
        if (selectedPosition && selectedLevel && selectedSubDepartment) {
            const existingPosition = selectedSubDepartment.months_limit.find(
                (p: any) => p.position_id === selectedPosition.id
            );

            if (existingPosition) {
                const existingLevel = existingPosition.levels.find(
                    (l: any) => l.level_id === selectedLevel._id
                );

                return existingLevel ? existingLevel.number_of_months_min : "";
            }
        }
        return "";
    };


    useEffect(() => {
        if (selectedPosition && selectedLevel && selectedSubDepartment) {
            const savedLimit = getSavedLimit();
            const savedMinLimit = getSavedMinLimit();
            setCurrentLimitValue(savedLimit);
            setCurrentMinLimitValue(savedMinLimit);
        }
    }, [selectedPosition, selectedLevel, selectedSubDepartment]);


    const handleSubmit = async () => {
        if (!selectedSubDepartment || !selectedPosition || !selectedLevel) {
            console.error("Sub-department, position, or level not selected");
            return;
        }
        if(!currentMinLimitValue){
            FailToast("please type Min Limit Value", t)
        }
        try {
            const response = await fetchWithToken(`/departments/${DepartmentId}/months-limit`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    sub_department_id: selectedSubDepartment._id,
                    position_id: selectedPosition.id,
                    level_id: selectedLevel._id,
                    number_of_months: currentLimitValue,
                    number_of_months_min: currentMinLimitValue,
                }),
            });
            SuccessToast(t("Updated"), t("Limits Updated Successfully"), t);

            // Update the state after successful submission
            setFetchedData((prevData: any) => {
                const updatedData = { ...prevData };

                const subDepartmentIndex = updatedData.sub_departments.findIndex(
                    (subDept: any) => subDept._id === selectedSubDepartment._id
                );

                if (subDepartmentIndex !== -1) {
                    const updatedSubDepartment = { ...updatedData.sub_departments[subDepartmentIndex] };

                    const positionIndex = updatedSubDepartment.months_limit.findIndex(
                        (limit: any) => limit.position_id === selectedPosition.id
                    );

                    if (positionIndex !== -1) {
                        const updatedPosition = { ...updatedSubDepartment.months_limit[positionIndex] };

                        const levelIndex = updatedPosition.levels.findIndex(
                            (levelObj: any) => levelObj.level_id === selectedLevel._id
                        );

                        if (levelIndex !== -1) {
                            updatedPosition.levels[levelIndex].number_of_months = currentLimitValue;
                            updatedPosition.levels[levelIndex].number_of_months_min = currentMinLimitValue;
                        } else {
                            updatedPosition.levels.push({
                                level_id: selectedLevel._id,
                                number_of_months: currentLimitValue,
                                number_of_months_min: currentMinLimitValue,
                            });
                        }

                        updatedSubDepartment.months_limit[positionIndex] = updatedPosition;
                    } else {
                        updatedSubDepartment.months_limit.push({
                            position_id: selectedPosition.id,
                            levels: [
                                {
                                    level_id: selectedLevel._id,
                                    number_of_months: currentLimitValue,
                                    number_of_months_min: currentMinLimitValue,
                                },
                            ],
                        });
                    }

                    updatedData.sub_departments[subDepartmentIndex] = updatedSubDepartment;
                }

                return updatedData;
            });

            setLimitUpdated(true);
        } catch (error) {
            console.error("Error updating limits:", error);
            setLimitUpdated(false);
        }
    };
    return (
        <div className="w-full">
            <div className="flex flex-col md:flex-row justify-between items-center w-full mb-9 p-4 bg-gray-50 rounded-lg shadow-md">
                <div className="flex flex-col md:flex-row gap-8 mb-4 md:mb-0">
                    <div className="text-lg font-bold">{t("Details For Department")} {fetchedData?.name}</div>
                </div>
                {/* <div>
                    <button
                        onClick={handleSubmit}
                        className="px-6 py-2 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition-all duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 w-full md:w-auto"
                    >
                        {t("Submit")}
                    </button>
                </div> */}
            </div>

            <div className="flex flex-col gap-4">
                {currentStep === 1 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4">{t("Step 1: Select a Sub-Department")}</h2>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                            {fetchedData?.sub_departments?.map((subDepartment: any) => (
                                <button
                                    key={subDepartment._id}
                                    onClick={() => handleChooseSubDepartment(subDepartment)}
                                    className="p-3 bg-blue-200 rounded-md hover:bg-blue-300"
                                >
                                    {subDepartment.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                {currentStep === 2 && (
                    <div>
                        <div className="flex flex-row items-center mb-4">
                            <div
                                onClick={() => setCurrentStep(1)}
                                className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                            >
                                <BackIcon />
                            </div>
                            <h2 className="text-xl font-bold">
                                {t("Step 2: Select a Position for")}{selectedSubDepartment?.name}
                            </h2>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                            {positions.map((position) => (
                                <button
                                    key={position.id}
                                    onClick={() => handleChoosePosition(position)}
                                    className="p-3 bg-blue-200 rounded-md hover:bg-blue-300"
                                >
                                    {position.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                {currentStep === 3 && (
                    <div>
                        <div className="flex flex-row items-center mb-4">
                            <div
                                onClick={() => {
                                    setCurrentStep(2)
                                    setLimitUpdated(false)

                                }}
                                className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                            >
                                <BackIcon />
                            </div>
                            <h2 className="text-xl font-bold">
                                {t("Step 3: Select a Level for")}  {selectedPosition?.name}
                            </h2>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                            {selectedPosition?.levels?.map((level: any) => (
                                <button
                                    key={level._id}
                                    onClick={() => handleChooseLevel(level)}
                                    className="p-3 bg-blue-200 rounded-md hover:bg-blue-300"
                                >
                                    {level.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                {currentStep === 4 && (
                    <div>
                        <div className="flex flex-row items-center mb-4">
                            <div
                                onClick={() => {
                                    setCurrentStep(3)
                                    setLimitUpdated(false)
                                }}
                                className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                            >
                                <BackIcon />
                            </div>
                            <h2 className="text-xl font-bold">
                                {t("Step 4: Set Limit for")} {selectedLevel?.name} {limitUpdated === true && <span className="text-[red]">Updated</span>}
                            </h2>
                        </div>
                        <div>
                            <div>
                                <div className="mb-1 text-[18px]">Select Max Limit</div>
                                <input
                                    type="number"
                                    value={currentLimitValue}
                                    onChange={(e) => setCurrentLimitValue(e.target.value)} // Local state only
                                    placeholder="Enter Max months limit"
                                    className="p-2 border rounded-md w-full"
                                    min={MIN_MONTHS}
                                    max={MAX_MONTHS}
                                    step="1"
                                />
                                <div className="mb-1 text-[18px]">Select Min Limit</div>
                                <input
                                    type="number"
                                    value={currentMinLimitValue}
                                    onChange={(e) => setCurrentMinLimitValue(e.target.value)} // Local state only
                                    placeholder="Enter Min months limit"
                                    className="p-2 border rounded-md w-full"
                                    min={MIN_MONTHS}
                                    max={MAX_MONTHS}
                                    step="1"
                                />
                                <button
                                    onClick={handleSubmit}
                                    className="px-6 py-2 mt-8 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition-all duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 w-full md:w-auto"
                                >
                                    {t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DepartmentDetails);
