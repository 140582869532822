import { useEffect, useState } from "react";
import moment from "moment";
import Profile from "./modals/profile";
import Notifications from "./modals/notifications";
import Chats from "./modals/chats";
import Logout from "./modals/logout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../utils/api";
import { setAdminStatus, setUser, setUserPermissions } from "../utils/redux";

const GetGreeting = () => {
  const currentHour = moment().hour();
  const { t, i18n } = useTranslation();
  if (currentHour < 12) return t("Good Morning");
  else if (currentHour < 18) return t("Good Afternoon");
  else return t("Good Evening");
};

const Header = () => {
  const dispatch = useDispatch()
  const UserRoleId = useSelector((state: any) => state?.user?.role_id);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showChats, setShowChats] = useState<boolean>(false);
  const [showLocale, setShowLocale] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<string | boolean>(false);
  const [userName, setUserName] = useState("");
  const [profileimg, setProfileimg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const userdata = async () => {
    setLoading(true);
    try {
      const data = await fetchWithToken("/users/me", {
        method: "GET",
      });
      setUserName(data?.data?.first_name);
      setProfileimg(data?.data?.profile_picture);
      dispatch(setUser(data?.data))
      dispatch(setAdminStatus(data?.data?.role?.code === "admin" ? true : false)) //! EDIT THIS TO admin THE REAL STATE
      getRoleDetails(data?.data?.role._id)
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setLoading(false);
    }
  };

  const getRoleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/roles/${id}`, { method: "GET" });
      const newFlattenedPermissions = (Object.values(data.data.permissions).flat() as { name: string }[])
        .map((permission) => permission.name);
      dispatch(setUserPermissions(newFlattenedPermissions))
    } catch (error) {
      console.error("Failed to fetch role:", error);
    }
  };

  // Inside the language toggle function
const handleLanguageChange = () => {
  const newLanguage = i18n.language === "ar" ? "en" : "ar";
  i18n.changeLanguage(newLanguage);
  localStorage.setItem("language", newLanguage); // Save the selected language
};


useEffect(() => {
  const savedLanguage = localStorage.getItem("language");
  if (savedLanguage) {
    i18n.changeLanguage(savedLanguage); // Set the saved language
  }
  userdata();
}, []);

  useEffect(() => {
    userdata();
  }, []);

  return (
    <div className="flex flex-col lg:px-[16px] md:flex-row items-center justify-between px-4 md:px-8 ">
      <Profile
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />
      <div className="text-center md:text-left">
        <div className="text-xl md:text-2xl font-medium">

          {`${GetGreeting()}, ${userName || ''}`}
        </div>
        <div className="text-sm md:text-base text-gray-600">
          {moment(new Date()).format("ddd, DD MMM YYYY")}
        </div>
      </div>
      <div className="flex items-center mt-2 md:mt-0 relative">
        <img
          alt="lang"
          src={require("../assets/world.png")}
          onClick={handleLanguageChange}
          className={`w-8 md:w-10 p-2 rounded-full mr-2 cursor-pointer`}
        />
        <img
          alt="message"
          src={require("../assets/message.png")}
          onClick={() => {
            if (!showChats) setShowNotification(false);
            setShowChats((showChats) => !showChats);
          }}
          className={`w-8 md:w-10 p-2 rounded-full ${showChats ? "bg-green-200" : ""
            } cursor-pointer`}
        />
        <img
          alt="notification"
          src={require("../assets/notification.png")}
          onClick={() => {
            if (!showNotification) setShowChats(false);
            setShowNotification((showNotification) => !showNotification);
          }}
          className={`w-8 md:w-10 p-2 mx-3 rounded-full ${showNotification ? "bg-green-200" : ""
            } cursor-pointer`}
        />
        <img
          alt="dp"
          // src={profileimg || require("../assets/noProfile.png")}
          src={profileimg || require("../assets/noProfile.png")}
          onClick={() => {
            setIsDropdown(!isDropdown);
            setShowChats(false);
            setShowNotification(false);
          }}
          width={40}
          height={40}
          className="lg:w-10 lg:h-10 h-6 w-6 cursor-pointer rounded-full"
        />
        {isDropdown && (
          <div className="absolute top-[60px] right-0 w-[250px] md:w-[15vw] bg-[#F7F8F7] rounded-lg shadow-lg z-30">
            <button
              onClick={() => {
                setModalVisible("me");
                setIsDropdown(false);
              }}
              className="w-full h-[42px] rounded-lg px-4 py-2 border-b flex items-center hover:bg-gray-200"
            >
              <img src={require("../assets/settings.png")} alt="" className="w-4 h-4 mr-2" />
              <div className="text-base font-normal text-gray-800">
                {t("Profile")}
              </div>
            </button>
            <button
              onClick={() => {
                setIsLogout(true);
                setIsDropdown(false);
              }}
              className="w-full h-[42px] rounded-lg px-4 py-2 border-b flex items-center hover:bg-gray-200"
            >
              <img src={require("../assets/logout.png")} alt="" className="w-4 h-4 mr-2" />
              <div className="text-base font-normal text-gray-800">
                {t("Logout")}
              </div>
            </button>
          </div>
        )}
      </div>
      <Notifications
        showNotification={showNotification}
        setShowNotification={setShowNotification}
      />
      <Chats showChats={showChats} setShowChats={setShowChats} />
      <Logout isLogout={isLogout} setIsLogout={setIsLogout} />
    </div>
  );
};

export default Header;