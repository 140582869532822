import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import Location from "../modals/location";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";

interface Location {
  _id: number;
  name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}

interface LocationsProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Locations: React.FC<LocationsProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const [content, setContent] = useState<string>("");
  const [locations, setLocations] = useState<Location[]>([]);
  const { t, i18n } = useTranslation();

  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);

  const fetchLocations = async () => {
    try {
      const response = await fetchWithToken(`/locations?page=${currentPage}`, {
        method: "GET",
      });
      setLocations(response?.data);
      setCurrentPage(response?.page);
      setTotalPages(Math.max(1, Math.ceil(response.total / response?.limit)));
      setTotalCount(response?.limit)
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };
  

  useEffect(() => {
    fetchLocations();
  }, [currentPage, count]);

  return (
    <>
      <Location
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchLocations={fetchLocations}
      />
      <DeleteModal
        route="locations"
        //content={content}
        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchLocations}
      />
    <Table className="relative mt-5 w-full">
  <TableHead className="hidden lg:table-header-group">
    <TableRow className="bg-[#F7F8F7]">
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
          {t("Hospital")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
          {t("Address")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
        {t("City")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
          {t("State")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
          {t("Country")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0">
        <div className="flex items-center">
           {t("PostalCode")}
          <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
        </div>
      </TableHeader>
      <TableHeader className="!outline-none !border-b-0"></TableHeader>
    </TableRow>
  </TableHead>

  <TableBody className="w-full">
    {Array.isArray(locations) && locations?.map((each) => (
      <TableRow
        key={each?._id}
        className="flex flex-col lg:table-row mb-4 lg:mb-0 border lg:border-none w-full"
      >
        {/* Hospital */}
        <TableCell className="lg:hidden font-bold bg-gray-100">Hospital</TableCell>
        <TableCell >{each?.name}</TableCell>

        {/* Address */}
        <TableCell className="lg:hidden font-bold bg-gray-100">Address</TableCell>
        <TableCell >
          {each?.addr_one}
          <div className="text-[#5D6561]">{each?.addr_two}</div>
        </TableCell>

        {/* City */}
        <TableCell className="lg:hidden font-bold bg-gray-100">City</TableCell>
        <TableCell >{each?.city}</TableCell>

        {/* State */}
        <TableCell className="lg:hidden font-bold bg-gray-100">State</TableCell>
        <TableCell >{each?.state}</TableCell>

        {/* Country */}
        <TableCell className="lg:hidden font-bold bg-gray-100">Country</TableCell>
        <TableCell >{each?.country}</TableCell>

        {/* Postal Code */}
        <TableCell className="lg:hidden font-bold bg-gray-100">Postal Code</TableCell>
        <TableCell >{each?.postal_code}</TableCell>

        {/* Edit & Delete Actions */}
        <TableCell className="flex justify-end w-full mt-4 lg:mt-0">
          <div
            onClick={() => setModalVisible(each._id)}
            className="w-[60px] h-full flex justify-center items-center cursor-pointer"
          >
            <img alt="editIcon" src={editIcon} className="w-6 h-6" />
          </div>
          <div
            onClick={() => {
              setDeleteRequestModal(each._id);
              setContent(`schedule of ${each?.name}`);
            }}
            className="w-[60px] h-full flex justify-center items-center cursor-pointer"
          >
            <img alt="deleteIcon" src={deleteIcon} className="w-6 h-6" />
          </div>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

    </>
  );
};

export default Locations;
