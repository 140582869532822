import React, { useEffect, useState } from "react";
import moment from "moment";
import Tooltip from "./tooltip";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../utils/api";
import SwapRequest from "./modals/swapRequest";
import { useSelector } from "react-redux";

// @ts-ignore
// import TimeAgo from "react-timeago";

interface SwapRequests {
  to_schedule: any;
  from_schedule: any;
  createdAt(createdAt: any): React.ReactNode | Iterable<React.ReactNode>;
  from_user: any;
  id: number;
  schedule_from: string;
  startfrom: string;
  created_datetime: string;
  endfrom: string;
  shifttypefrom: string;
  profile_from: number;
  first_name_from: string;
  last_name_from: string;
  dept_from: string;
  position_from: string;
  code_name_from: string;
  location_from: string;
  schedule_to: string;
  startto: string;
  endto: string;
  shifttypeto: string;
  profile_to: number;
  first_name_to: string;
  last_name_to: string;
  dept_to: string;
  position_to: string;
  code_name_to: string;
  location_to: string;
  req_message: string;
}

const SwapRequests = (props) => {

  const { t, i18n } = useTranslation();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isSettingModalVisible, setIsSettingModalVisible] = useState<boolean>(false);
  const [swapRequests, setSwapRequests] = useState<SwapRequests[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [selectedSwapRequest, setSelectedSwapRequest] =
    useState<SwapRequests>();
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const fetchSwapRequests = async () => {
    try {
      const data = await fetchWithToken("/swap-requests?status=pending", {
        method: "GET",
      });
      setSwapRequests(data?.data?.from_user);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [refresh]);

  const formatTimeDifference = (createdDateTime: any) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);
  
    if (days === 1) {
        return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
        return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
        return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
        return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
        return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
        return t("minutes_ago", { minutes });
    } else {
        return t("now");
    }
  };

  return (
    <>
      <div className="text-[24px] font-[700] mt-[24px] mb-[16px]">
        {t("SwapRequest")}
      </div>
      <SwapRequest
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        data={selectedSwapRequest}
        handleRefresh={handleRefresh}
      />

  

{Array.isArray(swapRequests) && swapRequests?.map((item, index) => (
  <div
    className="bg-[#f7f8f7] rounded-[8px] w-full sm:w-[90%] md:w-full max-h-[auto] flex flex-col justify-between my-2 p-3 sm:p-4 py-6"
    key={index}
  >
    {/* Request Header Section */}
    <div className="w-full flex flex-col md:flex-row items-start justify-between mb-4">
      <div className="flex items-center">
        <div className="text-[16px] sm:text-[18px] font-[700] flex items-center">
          <Tooltip text={`${item?.from_user?.first_name} ${item?.from_user?.last_name} had a shift ${formatTimeDifference(item.created_datetime)}`}>
            <img
              alt="warning2"
              src={require("../assets/warning2.png")}
              className="w-[20px] sm:w-[20px] mr-2"
            />
          </Tooltip>
          <img alt="spark" src={require("../assets/spark.png")} className="w-[15px] mr-2" />
          {item?.from_user?.first_name} {item?.from_user?.last_name}
        </div>
        <div className="text-[14px] font-[400] ml-2 text-[#5d6561]">
          {t("RequestASwap")}
        </div>
      </div>
      <div className="text-[12px] sm:text-[14px] font-[600]">
        {formatTimeDifference(item.createdAt)}
      </div>
    </div>

    {/* From and To Sections */}
    <div className="flex flex-col sm:flex-row justify-between mb-4">
      {/* "From" Section */}
      <div className="h-[auto] w-full sm:w-[45%] md:w-[auto] flex flex-col justify-between mb-3 sm:mb-0">
        <div className="text-[12px] sm:text-[14px] text-[#5d6561]">{t("From")}</div>
        <div className="text-[12px] sm:text-[14px] flex items-center w-full">
          <img
            alt="calender"
            src={require("../assets/calender.png")}
            className="w-[16px] h-[16px]"
          />
          <div className="text-[14px] sm:text-[16px] ml-3">
            {moment(item?.from_schedule?.date).isValid()
              ? moment(item?.from_schedule?.date).format("ddd, DD MMM YYYY")
              : t("Invalid date format")}
          </div>
        </div>
        <div className="text-[12px] sm:text-[14px] flex items-center w-full mt-2">
          <img alt="clock" src={require("../assets/clock.png")} className="w-[16px] h-[16px]" />
          <div className="text-[14px] sm:text-[16px] ml-3">
            {moment(item?.from_schedule?.shift?.start_time, "HH:mm:ss").isValid()
              ? moment(item?.from_schedule?.shift?.start_time, "HH:mm:ss").format("h:mm A")
              : t("Invalid date")} -{" "}
            {moment(item?.from_schedule?.shift?.end_time, "HH:mm:ss").isValid()
              ? moment(item?.from_schedule?.shift?.end_time, "HH:mm:ss").format("h:mm A")
              : t("Invalid date")}
          </div>
        </div>
      </div>

      {/* "To" Section */}
      <div className="h-[auto] w-full sm:w-[45%] md:w-[auto] flex flex-col justify-between">
        <div className="text-[12px] sm:text-[14px] text-[#5d6561]">{t("To")}</div>
        <div className="text-[12px] sm:text-[14px] flex items-center w-full">
          <img
            alt="calender"
            src={require("../assets/calender.png")}
            className="w-[16px] h-[16px]"
          />
          <div className="text-[14px] sm:text-[16px] ml-3">
            {moment(item?.to_schedule?.date).isValid()
              ? moment(item?.to_schedule?.date).format("ddd, DD MMM YYYY")
              : t("Invalid date format")}
          </div>
        </div>
        <div className="text-[12px] sm:text-[14px] flex items-center w-full mt-2">
          <img alt="clock" src={require("../assets/clock.png")} className="w-[16px] h-[16px]" />
          <div className="text-[14px] sm:text-[16px] ml-3">
            {moment(item?.to_schedule?.shift?.start_time, "HH:mm:ss").isValid()
              ? moment(item?.to_schedule?.shift?.start_time, "HH:mm:ss").format("h:mm A")
              : t("Invalid date")} -{" "}
            {moment(item?.to_schedule?.shift?.end_time, "HH:mm:ss").isValid()
              ? moment(item?.to_schedule?.shift?.end_time, "HH:mm:ss").format("h:mm A")
              : t("Invalid date")}
          </div>
        </div>
      </div>
    </div>

    {/* Details Button */}
    <div className="w-full flex items-center justify-center mt-4 sm:mt-6">
      <div
        onClick={() => {
          setSelectedSwapRequest(item);
          setModalVisible(true);
          setIsSubmit(true);
        }}
        className="w-[120px] sm:w-[140px] md:w-[160px] h-[35px] sm:h-[40px] md:h-[45px] rounded-[8px] bg-[#00a843] hover:bg-[#389365] text-[#fff] text-[12px] sm:text-[14px] md:text-[16px] font-[700] flex items-center justify-center cursor-pointer sm:mx-auto"
      >
        {t("Details")}
      </div>
    </div>
  </div>
))}


    </>
  );
};

export default SwapRequests;