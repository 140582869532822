import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import AnimatedBtn from "../animatedBtn";
import moment from "moment";
import { useSelector } from "react-redux";



const SwapShiftConfirm: any = ({ isModalVisible, setModalVisible, swapTo }) => {
  const userDepartment = useSelector((state: any) => state.user.user.department);
  const theSelectedSchedule = useSelector(
    (state: any) => state?.user?.firstSchedule
  );
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isDecline, setIsDecline] = useState<boolean>(false);

  const [status, setStatus] = useState<string>("");
  const [schedule, setSchedule] = useState<any>();
  const handleCreateSwapRequest = async () => {
    const payload = {
      "from_schedule_id": theSelectedSchedule?.id,
      "to_schedule_id": swapTo?.id
    };
  
    setStatus("onclic"); // Set the status to "onclic" when the request is triggered
  
    try {
      const response = await fetchWithToken(`/swap-requests`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      setModalVisible(!isModalVisible); // Toggle modal visibility
  
      if (response?.message) {
        // FailToast(response?.message);
        setStatus("fail"); // Set the status to "fail" if there is a message indicating failure
      } else {
        // SuccessToast("Requested", `Your request has been sent`);
        setStatus("success"); // Set the status to "success" on successful request
      }
    } catch (error) {
      setStatus("fail"); // Set the status to "fail" in case of an error
      // FailToast("Failed to send swap request. Please try again.");
      console.error("Error updating shift swap", error); // Log the error for debugging
    }
  };


  const fetchSchedules = async () => {
    try {
      const data = await fetchWithToken(
        `/schedules/department?department_id=${userDepartment._id}`,
        {
          method: "GET",
        }
      );
      setSchedule(data?.data || []);


    } catch (error) {
      console.error(t("Failed to fetch locations:"), error);
    }
  };


  useEffect(() => {
    fetchSchedules()
  }, []);
  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center align-middle z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 w-full max-w-md h-[28%] overflow-auto m-auto capitalize bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold mt-[30px]">
              {t("swapRequestConfirmation", {
          fromDate: theSelectedSchedule?.date,
          fromShiftType: theSelectedSchedule?.shift?.shift_type,
          toDate: swapTo?.date,
          toShiftType: swapTo?.shift?.shift_type,
        })}

                </div>

          </div>
          <div className="w-[350px] flex gap-3 mt-[50px]">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[168px] h-[40px] rounded-[8px] border border-[#00A843] text-[#389365] hover:border-[#1F7549] hover:text-[#1F7549] ${isDecline ? "text-[#56B77B] border-[#56B77B]" : ""
                } text-[16px] font-[700] px-[24px] py-[8px]`}
            >
              {t("Cancel")}
            </button>
            <div className="w-[168px]">
              <AnimatedBtn
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleCreateSwapRequest();
                }}
                txt={t("Submit")}
                status={status}
                setStatus={setStatus}
              />
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default SwapShiftConfirm;
