import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import fetchWithToken from "../../utils/api";
import calenderDark from "../../assets/calenderDark.png";
import "./style.css";
import { Select } from "@headlessui/react";

interface Block {
  block: number;
  week: string;
  date: string;
  month: string;
}

const colors = [
  "#c0b0ff",
  "#a5eebe",
  "#fcee71",
  "#f6abd8",
  "#f28585",
  "#fdd484",
  "#b8d9ff",
  "#e0e7ff", // Light pastel blue
  "#d5f9e3", // Soft mint green
  "#fff7c2", // Light buttercream yellow
  "#ffe4f3", // Pale pink
  "#f4d5ff"  // Light lavender
];

const colorsMap = new Map<string, string>();

interface RotationTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}

const RotationTable: React.FC<RotationTableProps> = ({
  refresh,
  handleRefresh,
}) => {
  const userDepartment = useSelector(
    (state: any) => state.user.user.department
  );
  const [dapartments, setDepartments] = useState([]);
  const [activeDepartment, setActiveDepatment] = useState<any>();
  const [activeTab, setActiveTab] = useState<any>(0);
  const [originalSchedules, setOriginalSchedules] = useState();

  const SelectedSchedule = useSelector(
    (state: any) => state?.user?.firstSchedule
  );
  const [week, setWeek] = useState<any>();
  const [schedule, setSchedule] = useState<any>();
  const { t } = useTranslation();

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  // Generate the data for the blocks
  const generateData = (): Block[] => {
    const weeksInYear = 52;
    const blocks = 13;
    const weeksPerBlock = weeksInYear / blocks;
    const data: Block[] = [];

    for (let i = 0; i < blocks; i++) {
      const startWeek = i * weeksPerBlock + 1;
      const endWeek = (i + 1) * weeksPerBlock;

      const startDate = moment()
        .startOf("year")
        .add(startWeek - 1, "weeks");
      const endDate = moment()
        .startOf("year")
        .add(endWeek - 1, "weeks")
        .add(6, "days");

      const startMonth = startDate.format("MMM"); // Abbreviated month name
      const endMonth = endDate.format("MMM"); // Abbreviated month name

      // If block spans multiple months, show the range; otherwise, just the single month
      const month =
        startMonth === endMonth ? startMonth : `${startMonth}-${endMonth}`;

      data.push({
        block: i + 1,
        week: `${Math.floor(startWeek)}-${Math.floor(endWeek)}`,
        date: `${startDate.format("DD-MM")} - ${endDate.format("DD-MM")}`,
        month: month, // Shortened months
      });
    }

    return data;
  };

  const fetchSchedule = async () => {
    if (!activeDepartment) return;
    try {
      const data = await fetchWithToken(
        `/schedules-rules/rotations/department?department_id=${activeDepartment ? activeDepartment : userDepartment._id
        }`,
        { method: "GET" }
      );
      setOriginalSchedules(data?.data);
      const subDepartments = activeDepartment?.sub_departments || [];
      const groupedByUser = data?.data.reduce((acc, schedule) => {
        const { user } = schedule;
        const userId = user._id;

        if (!acc[userId]) {
          acc[userId] = {
            id: user._id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            contact: user.contact,
            birthdate: user.birthdate,
            schedules: [],
          };
        }

        // Find the full sub-department data
        const subDepartmentData = subDepartments.find(
          (sub) => sub._id === schedule.sub_department_id
        );

        acc[userId].schedules.push({
          id: schedule.id,
          date: `${moment(schedule?.start_date).format("DD-MM")} - ${moment(
            schedule?.end_date
          ).format("DD-MM")}`,
          shift_type: schedule.shift.shift_type,
          start_time: schedule.shift.start_time,
          end_time: schedule.shift.end_time,
          is_rotation: schedule.shift.is_rotation,
          subdepartment: schedule?.sub_department,
        });

        return acc;
      }, {});

      const combinedSchedules = Object.values(groupedByUser);

      setSchedule(combinedSchedules || []);
    } catch (error) {
      console.error(t("Failed to fetch locations:"), error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });
      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
          sub_departments: each?.sub_departments,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  useEffect(() => {
    setActiveDepatment(userDepartment?._id);
  }, [userDepartment]);

  useEffect(() => {
    fetchDepartments();
    setActiveDepatment(userDepartment?._id)
  }, []);

  useEffect(() => {
    fetchSchedule();
  }, [activeDepartment]);

  return (
    <div>
      <div className="flex items-center xl:my-2 xl:h-[40px] h-auto w-full justify-center align-middle ">
        <div className="flex xl:flex-row flex-col xl:justify-around justify-center align-middle xl:w-full h-full ">
          <p className="text-[20px] font-[700] mt-1  text-nowrap text-center">
            {t("rotation_data")}
          </p>
          <div className="xl:mt-0 mt-1">
            {/* <WeekPicker onChange={(e: any) => setWeek(e)} /> */}
          </div>

          <div className="ml-[1%]">
            <Select
              className="xl:w-[210px] w-[250px] h-[40px] border border-[#7E8581] rounded-md ml-2"
              name="deptId"
              onChange={(e: any) => setActiveDepatment(e.target.value)}
              value={activeDepartment}
            >
              {dapartments?.map((dep: any) => (
                <option key={dep?._id} value={dep?._id}>
                  {dep?.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto xl:overflow-y-scroll overflow-y-auto xl:mt-0 mt-2 scrollbar-hidden h-[90%]">
        <Table className="min-w-full overflow-x-auto overflow-y-auto h-[71vh]">
          <TableHead>
            <TableRow>
              <TableHeader
                style={{ border: 0, padding: 0 }}
                className="xl:w-[60px] w-[40px]"
              >
                <div className="flex justify-center">
                  <img
                    alt="calenderDark"
                    src={calenderDark}
                    className="xl:w-[20px] w-[15px]"
                  />
                </div>
              </TableHeader>
              {generateData().map((block, index) => (
                <TableHeader
                  key={block.week}
                  className="w-[80px]"
                  style={{ border: 0, padding: 5 }}
                >
                  <div className="w-full text-black h-[80px] flex flex-col items-center justify-center rounded-[4px] bg-[#e8ebe9]">
                    <div className="text-[10px] xl:text-[12px] font-[700]">
                      {block.month}
                    </div>
                    <div className="text-[10px] xl:text-[12px] font-[500] my-[3px]">
                      {t("block")} {index + 1}
                    </div>
                    <div className="text-[10px] xl:text-[12px] font-[400]">
                      {block.date}
                    </div>
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule?.map((each) => (
              <TableRow key={each.id}>
                <TableCell
                  style={{ border: 0, padding: 0 }}
                  className="bg-[#f7f8f7] font-[600] text-[12px]"
                >
                  <div className="h-[50px] pl-3 w-[80px] flex flex-wrap items-center">
                    <div className="mr-1">{each?.first_name}</div>
                    <div>{each?.last_name}</div>
                  </div>
                </TableCell>

                {generateData().map((block, index) => (
                  <TableCell
                    key={block.week}
                    style={{
                      border: 0,
                      padding: 0,
                      width: "100vh",
                      overflow: "auto",
                    }}
                  >
                    {each?.schedules?.filter((schdl) => {
                      if (!colorsMap.has(schdl.subdepartment.name)){
                        const color = colors[colorsMap.size];
                        colorsMap.set(schdl.subdepartment.name, color);
                      }
                      const [startDate, endDate] = schdl.date.split(" - ");
                      return (
                        startDate === block.date.split(" - ")[0] &&
                        endDate === block.date.split(" - ")[1]
                      );
                    }).length ? (
                      each?.schedules
                        ?.filter((schdl) => {
                          const [startDate, endDate] = schdl.date.split(" - ");
                          return (
                            startDate === block.date.split(" - ")[0] &&
                            endDate === block.date.split(" - ")[1]
                          );
                        })
                        .map((schdl) => (
                          <div
                            key={schdl.id}
                            className="ml-2 max-w-[115px] overflow-hidden"
                          >
                            <div
                              className="rounded-[3px] w-full h-[70px] flex flex-col justify-center"
                              style={{
                                background: colorsMap.get(schdl.subdepartment.name),
                                opacity: 0.8,
                                border:
                                  schdl?.id === SelectedSchedule?.id
                                    ? "1px solid #00a843"
                                    : "",
                              }}
                            >
                              {/* <div className="font-[700] text-[10px] xl:text-[12px] mx-1">
                                {userDepartment?.name}
                              </div> */}
                              <div className="font-[700] text-[10px] xl:text-[12px] mx-1">
                                {schdl?.subdepartment?.name}
                              </div>
                              <div className="text-[8px] xl:text-[10px] mx-1">
                                {`${moment(schdl?.start_time, "HH:mm:ss").format(
                                  "hh:mm A"
                                )} - ${moment(schdl?.end_time, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}`}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="flex items-center justify-center w-full max-w-[115px]">
                        <div className="bg-[#f7f8f7] rounded-[3px] w-full h-[70px] italic flex flex-col justify-center pl-3 m-1 relative" />
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

    </div>
  );
};

export default memo(RotationTable);