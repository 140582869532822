import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface positionProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchPositions: () => void;
}

const PositionModal: any = ({
  isModalVisible,
  setModalVisible,
  fetchPositions,
}) => {
  const isAdd = isModalVisible === true;
  const { t } = useTranslation();
  const [levels, setLevels] = useState<{ name: string; level: number }[]>([]);

  const formik = useFormik({
    initialValues: {
      position_name: "",
    },
    validationSchema: Yup?.object({
      position_name: Yup?.string()?.required(t("Positionrequired")),
    }),
    onSubmit: async (values) => {
      try {
        await fetchWithToken(
          isAdd ? "/positions" : `/positions/${isModalVisible}`,
          {
            method: isAdd ? "POST" : "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: values.position_name,
              levels: levels,
            }),
          }
        );
        setModalVisible(!isModalVisible);
        fetchPositions();
      } catch (error) {
        console.error(t("Error creating position"), error);
      }
    },
  });

  const addLevel = () => {
    setLevels([...levels, { name: "", level: 1 }]);
  };

  const handleLevelChange = (index: number, name: string, level: number) => {
    const updatedLevels = [...levels];
    updatedLevels[index] = { name, level };
    setLevels(updatedLevels);
  };

  const removeLevel = (index: number) => {
    setLevels(levels.filter((_, i) => i !== index));
  };

  const getPositionDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/positions/${id}`, { method: "GET" });
      formik.setFieldValue("position_name", data.data?.name);
      setLevels(data?.data?.levels)

    } catch (error) {
      console.error("Failed to fetch position:", error);
    }
  };

  useEffect(() => {
    formik.resetForm();
    if (typeof isModalVisible === "number" || typeof isModalVisible === "string") {
      getPositionDetails(isModalVisible);
    } else {
      setLevels([]);
    }
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        onClick={() => setModalVisible(!isModalVisible)}
        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex justify-center items-center"
      >
        <div className="container my-auto">
          <div
            onClick={(e) => e.stopPropagation()}
            className="py-5 max-w-[95%] sm:max-w-[60%] overflow-auto m-auto w-full sm:w-[385px] bg-white rounded-[8px] flex flex-col items-center"
          >
            <div className="text-center text-lg font-bold">{t("Position")}</div>
            <div className="text-sm text-[#101010]">
              <div className="font-bold">{t("Position")}</div>
              <input
                type="text"
                placeholder={t("PositionLabel")}
                name="position_name"
                required
                className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
                id="position_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.position_name}
                style={{
                  borderColor:
                    formik.touched.position_name && formik.errors.position_name
                      ? "#E23121"
                      : "#5D6561",
                }}
              />
              {formik.touched.position_name && formik.errors.position_name && (
                <div className="text-red-500 text-sm">{formik.errors.position_name}</div>
              )}
              <div className="font-bold">{t("Level")}</div>
              {levels.map((levelItem, index) => (
                <div key={index} className="flex items-center space-x-2 my-2">
                  <input
                    type="text"
                    placeholder={`${t("Level")} ${index + 1} ${t("Name")}`}
                    value={levelItem.name}
                    onChange={(e) =>
                      handleLevelChange(index, e.target.value, levelItem.level)
                    }
                    className="w-[202px] h-[40px] border rounded-[8px] p-2 outline-none"
                  />
                  <select
                    value={levelItem.level}
                    onChange={(e) =>
                      handleLevelChange(index, levelItem.name, Number(e.target.value))
                    }
                    className="w-[120px] h-[40px] border rounded-[8px] p-2 outline-none"
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {`${t("Level")} ${i + 1}`} 
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => removeLevel(index)}
                    className="text-red-500 font-bold"
                  >
                    &times;
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addLevel}
                className="text-blue-500 font-bold mt-2"
              >
                + {t("Add")}
              </button>
            </div>
            <div className="w-[350px] flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setModalVisible(!isModalVisible)}
                className="w-[168px] h-[40px] rounded-[8px] border border-[#00a843] text-[#00a843] hover:border-[#E23121] hover:text-[#E23121] text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                onClick={() => formik.handleSubmit()}
                className="w-[168px] rounded-[8px] bg-[#56b77b] hover:bg-[#00A843] text-[#F8FAF8] p-2 text-[16px] font-[700] px-[24px] py-[8px]"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default PositionModal;
