import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import PositionModal from "../modals/positionModal";
import DeleteModal from "../modals/deleteModal";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";

interface rolesProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Positions: React.FC<rolesProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const [deletePositionModal, setDeletePositionModal] = useState<
    boolean | number | string
  >(false);
  const { t, i18n } = useTranslation();
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const [positions, setPositions] = useState<any[]>([]);
  const [content, setContent] = useState<string>("");

  const fetchPositions = async () => {
    try {
      const data = await fetchWithToken(`/positions?page=${currentPage}`, {
        method: "GET",
      });
      setPositions(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit)
      
    } catch (error) {
      console.error("Failed to fetch positions:", error);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, [currentPage, count]);

  return (
    <>
      <Table className="relative mt-5 w-full">
        <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-[#F7F8F7] w-full">
            <TableHeader>{t("Position")}</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className="w-full">
          {Array.isArray(positions) &&
            positions?.map((position) => (
              <TableRow
                key={position.id}
                className="lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
              >
                <TableCell className="lg:hidden font-bold bg-gray-100 ">
                  {t("Position")}
                </TableCell>
                <TableCell className="w-full">
                  {position?.name}
                </TableCell>

                <TableCell className="flex justify-end w-full">
                  <div
                    onClick={() => setModalVisible(position.id)}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                  </div>
                  <div
                    onClick={() => {
                      setDeletePositionModal(position.id);
                      setContent(position.position_name);
                    }}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img
                      alt="deleteIcon"
                      src={deleteIcon}
                      className="w-6 h-6"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <PositionModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchPositions={fetchPositions}
      />
      <DeleteModal
        route="positions"
                //content={content}

        visibilityState={deletePositionModal}
        setState={setDeletePositionModal}
        fetchAllCall={fetchPositions}
      />
    </>
  );
};

export default Positions;
