import React, { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchWithToken from "../../utils/api";
import SuccessToast from "../../components/modals/successToast";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Back.svg";

const ShiftDetails = () => {
    const param = useParams();
    const ShiftId = param?.id;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [shiftData, setShiftData] = useState<any>();
    const [departmentsData, setDepartmentsData] = useState<any>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>({});
    const [selectedSubDepartment, setSelectedSubDepartment] = useState<any>();
    const [selectedSubDepartmentLimit, setSelectedSubDepartmentLimit] = useState<any>();
    const [selectedSubDepartmentLimitUpdated, setSelectedSubDepartmentLimitUpdated] = useState<any>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const [modalVisible, setModalVisible] = useState(false)
    const [positions, setPositions] = useState<any[]>([]);
    // const [seconddepartmentsData, setSecondDepartmentsData] = useState<any>([]);
    const [selectedData2, setSelectedData2] = useState<any[]>([]);
    const [secondDepartmentsData, setSecondDepartmentsData] = useState<any | null>(null);
    const [selectedPosition, setSelectedPosition] = useState<any | null>(null);
    const [selectedLevel, setSelectedLevel] = useState<any | null>(null);
    const [limitValue, setLimitValue] = useState(false);
    const [limitUpdated, setLimitUpdated] = useState(false);
    const [firstpartCurrStep, setFirstpartCurrStep] = useState(1);
    const [secondpartCurrStep, setSecondpartCurrStep] = useState(1);


    const transformData = (inputData) => {
        const { start_time, end_time, shift_type, register_config, department_limit } = inputData;

        const transformedRegisterConfig = register_config.map((config) => ({
            department_id: config.department_id,
            positions: config.positions.map((position) => ({
                position_id: position.position_id,
                levels: position.levels.map((level) => ({
                    level_id: level.level_id,
                    allowed: level.allowed,
                })),
            })),
        }));

        const departmentLimit = department_limit.map((config) => ({
            department_id: config.department_id,
            sub_departments: config.sub_departments.map((subDept) => ({
                sub_department_id: subDept.sub_department_id,
                limit: subDept?.limit,
            })),
        }));

        return {
            start_time,
            end_time,
            shift_type,
            department_limit: departmentLimit,
            register_config: transformedRegisterConfig,
        };
    };

    const fetchShift = async () => {
        try {
            const data = await fetchWithToken(`/shifts/${ShiftId}`, {
                method: "GET",
            });
            setShiftData(data?.data);
            let Mydata = transformData(data?.data);
            setSelectedData(Mydata?.department_limit)
            setSelectedData2(Mydata?.register_config)
        } catch (error) {
            console.error("Failed to fetch shifts:", error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const data = await fetchWithToken(`/departments`, {
                method: "GET",
            });
            setDepartmentsData(data?.data);
        } catch (error) {
            console.error("Failed to fetch departments:", error);
        }
    };

    const fetchPositions = async () => {
        try {
            const data = await fetchWithToken(`/positions`, {
                method: "GET",
            });
            setPositions(data?.data);


        } catch (error) {
            console.error("Failed to fetch positions:", error);
        }
    };


    useEffect(() => {
        fetchShift();
        fetchDepartments();
        fetchPositions()
    }, []);

    const MIN_LIMIT = 0;
    const MAX_LIMIT = 15;

    const handleChooseDepartment = (department: any) => {
        setSelectedDepartment(department);
        setSelectedSubDepartment(null);
        setSelectedSubDepartmentLimit("");
        setFirstpartCurrStep(2);
    };

    const handleChooseSubDepartment = (subDepartment) => {
        setSelectedSubDepartment(subDepartment);
        const department = selectedData.find((d) => d.department_id === selectedDepartment?._id);
        if (department) {
            const subDepartmentData = department.sub_departments.find(
                (sub) => sub.sub_department_id === subDepartment._id
            );
            setSelectedSubDepartmentLimit(subDepartmentData?.limit || "");
        } else {
            setSelectedSubDepartmentLimit("");
        }
    };

    
    const handleLimitChange = (value) => {
        let debounceTimeout;
        const numValue = parseFloat(value);
        if (!isNaN(numValue) && numValue >= MIN_LIMIT && numValue <= MAX_LIMIT) {
            setSelectedSubDepartmentLimit(numValue);
            setSelectedSubDepartmentLimitUpdated(false);
    
            clearTimeout(debounceTimeout);
    
            debounceTimeout = setTimeout(async () => {
                try {
                    const response = await fetchWithToken(`/shifts/${ShiftId}/department-limit`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            department_id: selectedDepartment?._id,
                            sub_department_id: selectedSubDepartment?._id,
                            limit: numValue,
                        }),
                    });
    
                    setSelectedSubDepartmentLimitUpdated(true);
                    setSelectedSubDepartmentLimit(numValue);
                    setModalVisible(true);
                } catch (error) {
                    console.error(error);
                    setSelectedSubDepartmentLimitUpdated(false);
                }
            }, 1000);
        }
    };
    

    useEffect(() => {
        if (selectedDepartment && selectedSubDepartment?._id) {
            const limit = selectedData
                ?.find((d) => d.department_id === selectedDepartment._id)
                ?.sub_departments.find((p) => p.sub_department_id === selectedSubDepartment._id)
        }
    }, [selectedDepartment, selectedSubDepartment]);

    const handleChooseSecondDepartment = (department) => {
        setSecondDepartmentsData(department);
        setSelectedPosition(null);
        setSelectedLevel(null);

        const existingDepartment = selectedData2.find((d) => d.department_id === department._id);
        if (!existingDepartment) {
            setSelectedData2((prev) => [
                ...prev,
                { department_id: department._id, positions: [] },
            ]);
        }
        setSecondpartCurrStep(2);
    };

    const handleChoosePosition = (position) => {
        setSelectedPosition(position);
        setSelectedLevel(null);

        const departmentData = selectedData2.find(
            (d) => d.department_id === secondDepartmentsData._id
        );

        if (departmentData) {
            const existingPosition = departmentData.positions.find(
                (p) => p.position_id === position.id
            );
            if (!existingPosition) {
                departmentData.positions.push({
                    position_id: position.id,
                    levels: [],
                });
                setSelectedData2([...selectedData2]);
            }
        }
        setSecondpartCurrStep(3);
    };

    const handleChooseLevel = (level) => {
        setSelectedLevel(level);

        const departmentData = selectedData2.find(
            (d) => d.department_id === secondDepartmentsData._id
        );
        const positionData = departmentData?.positions.find(
            (p) => p.position_id === selectedPosition.id
        );

        if (positionData) {
            const existingLevel = positionData.levels.find((l) => l.level_id === level._id);
            if (!existingLevel) {
                positionData.levels.push({
                    level_id: level._id,
                    allowed: false,
                });
                setSelectedData2([...selectedData2]);
            }
        }
        setSecondpartCurrStep(4);
    };


    useEffect(() => {
        if (selectedData2 && secondDepartmentsData?._id && selectedPosition?.id && selectedLevel?._id) {
            const allowed = selectedData2
                ?.find((d) => d.department_id === secondDepartmentsData._id)
                ?.positions.find((p) => p.position_id === selectedPosition.id)
                ?.levels.find((l) => l.level_id === selectedLevel._id)?.allowed;

            setLimitValue(allowed);
            setLimitUpdated(false)
        }
    }, [selectedData2, secondDepartmentsData, selectedPosition, selectedLevel]);



    const toggleAllowed = (event) => {
        let debounceTimeout;
        const updatedAllowedValue = !limitValue;
        setLimitUpdated(false);

        clearTimeout(debounceTimeout);

        debounceTimeout = setTimeout(async () => {
            try {
                const response = await fetchWithToken(`/shifts/${ShiftId}/register-config`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        department_id: secondDepartmentsData?._id,
                        position_id: selectedPosition?.id,
                        level_id: selectedLevel?._id,
                        allowed: updatedAllowedValue,
                    }),
                });

                setModalVisible(true);
                setLimitValue(updatedAllowedValue);
                setLimitUpdated(true);
            } catch (error) {
                console.error(error);
                setLimitUpdated(false);
            }
        }, 1000);
    };


    return (
        <div className="w-full overflow-scroll">
            <div className="w-full ">
                <div className="">{t("Details For")} {shiftData?.shift_type} {t("Shift")}</div>
                <div className="flex flex-col md:flex-row justify-between items-center w-full p-4 bg-gray-50 rounded-lg shadow-md">
                    <div className="flex flex-col md:flex-row gap-8 mb-4 md:mb-0">
                        <div className="text-gray-700 font-medium">
                            <span className="text-gray-500">{t("Start Time:")}</span> {shiftData?.start_time}
                        </div>
                        <div className="text-gray-700 font-medium">
                            <span className="text-gray-500">{t("End Time:")}</span> {shiftData?.end_time}
                        </div>
                    </div>

                </div>

                <div className="border border-t-2 my-[20px] " />
                <div className="flex justify-center align-middle w-full gap-[140px] ">
                    {/* First Section */}
                    <div className="w-full py-2 px-4 flex flex-col gap-2 ">
                        <h2 className="text-xl font-bold mb-1">  {t("Select Limit For Shift")}</h2>
                        {firstpartCurrStep === 1 && (
                            <div>
                                <h2 className="text-xl font-medium mb-4">{t("Select a Department")}</h2>
                                <div className="flex flex-col gap-2">
                                    {departmentsData?.map((item) => (
                                        <div
                                            key={item._id}
                                            onClick={() => handleChooseDepartment(item)}
                                            className={`${item._id === selectedDepartment?._id
                                                ? "bg-green-300 hover:bg-green-400"
                                                : "bg-green-200 hover:bg-green-300"
                                                } flex items-center justify-center p-3 rounded-md cursor-pointer`}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {firstpartCurrStep === 2 && selectedDepartment && (
                            <div>
                                <div className="flex">
                                    <div
                                        onClick={() => {
                                            setFirstpartCurrStep((prev) => prev - 1)
                                            setSelectedSubDepartmentLimitUpdated(false)
                                        }}
                                        className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                                    >
                                        <BackIcon />
                                    </div>
                                    <h2 className="text-xl font-medium mb-4">
                                        {t("Select a Sub-Department for")} {selectedDepartment.name}
                                    </h2>
                                </div>
                                <div className="flex flex-col gap-2">
                                    {selectedDepartment.sub_departments?.map((sub) => (
                                        <div
                                            key={sub._id}
                                            onClick={() => handleChooseSubDepartment(sub)}
                                            className={`${sub._id === selectedSubDepartment?._id
                                                ? "bg-green-300 hover:bg-green-400"
                                                : "bg-green-200 hover:bg-green-300"
                                                } flex items-center justify-center p-3 rounded-md cursor-pointer`}
                                        >
                                            {sub.name}
                                        </div>
                                    ))}
                                </div>

                                {selectedSubDepartment && (
                                    <div className="mt-4">
                                        <label htmlFor="limit" className="block text-sm font-medium text-gray-700">
                                            {t("Limit for")} {selectedSubDepartment?.name} {selectedSubDepartmentLimitUpdated === true && <span className="text-[red]">Updated</span>}
                                        </label>
                                        <input
                                            type="number"
                                            id="limit"
                                            value={selectedSubDepartmentLimit}
                                            onChange={(e) => handleLimitChange(e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            min={MIN_LIMIT}
                                            max={MAX_LIMIT}
                                        />

                                        {selectedSubDepartmentLimit !== "" &&
                                            (selectedSubDepartmentLimit < MIN_LIMIT ||
                                                selectedSubDepartmentLimit > MAX_LIMIT) && (
                                                <div className="text-red-500 text-sm mt-1">
                                                    {t(" Limit must be between")} {MIN_LIMIT} {t("and")} {MAX_LIMIT}.
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="border border-t-2 my-[20px]" />
                {/* Second Part */}
                <div className="w-full py-2 px-4 flex flex-col gap-2">
                    <h2 className="text-xl font-bold mb-1"> {t("Allowed For Shift")}</h2>
                    {secondpartCurrStep === 1 && (
                        <div>
                            <h2 className="text-xl font-medium mb-4"> {t("Select a Department")}</h2>
                            <div className="flex flex-col gap-4">
                                {departmentsData?.map((item) => (
                                    <div
                                        key={item._id}
                                        onClick={() => handleChooseSecondDepartment(item)}
                                        className={`${item._id === secondDepartmentsData?._id
                                            ? "bg-green-400 hover:bg-green-400"
                                            : "bg-green-200 hover:bg-green-300"
                                            } flex items-center justify-center p-3 rounded-md cursor-pointer`}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {secondpartCurrStep === 2 && secondDepartmentsData && (
                        <div>
                            <div className="flex">
                                <div
                                    onClick={() => setSecondpartCurrStep((prev) => prev - 1)}
                                    className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                                >
                                    <BackIcon />
                                </div>
                                <h2 className="text-xl font-medium mb-4">
                                    {t("Select a Position for")}{secondDepartmentsData.name}
                                </h2>
                            </div>
                            <div className="flex flex-col gap-4">
                                {positions?.map((item) => (
                                    <div
                                        key={item.id}
                                        onClick={() => handleChoosePosition(item)}
                                        className={`${item.id === selectedPosition?.id
                                            ? "bg-green-400 hover:bg-green-400"
                                            : "bg-green-200 hover:bg-green-300"
                                            } flex items-center justify-center p-3 rounded-md cursor-pointer`}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {secondpartCurrStep === 3 && selectedPosition && (
                        <div>
                            <div className="flex">
                                <div
                                    onClick={() => {
                                        setSecondpartCurrStep((prev) => prev - 1)
                                        setLimitUpdated(false)
                                    }}
                                    className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                                >
                                    <BackIcon />
                                </div>
                                <h2 className="text-xl font-medium mb-4">
                                    {t("Select a Level for")}{selectedPosition.name}
                                </h2>
                            </div>
                            <div className="flex flex-col gap-4">
                                {selectedPosition?.levels?.map((item) => (
                                    <div
                                        key={item._id}
                                        onClick={() => handleChooseLevel(item)}
                                        className={`${selectedLevel?._id === item._id
                                            ? "bg-green-400 hover:bg-green-400"
                                            : "bg-green-200 hover:bg-green-300"
                                            } flex items-center justify-center p-3 rounded-md cursor-pointer`}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {secondpartCurrStep === 4 && selectedLevel && (
                        <div>
                            <div className="flex">
                                <div
                                    onClick={() => {
                                        setSecondpartCurrStep((prev) => prev - 1)
                                        setLimitUpdated(false)
                                    }}
                                    className="flex flex-row justify-center items-center bg-green-300 rounded-lg mr-3 cursor-pointer hover:bg-green-400 w-[30px] h-[30px]"
                                >
                                    <BackIcon />
                                </div>
                                <h2 className="text-xl font-medium mb-4">{t("Allow Level")}</h2>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="flex items-center gap-4 p-4 w-full bg-gray-100 rounded-md shadow-sm">
                                    <label
                                        className="text-sm font-medium text-gray-700"
                                        htmlFor="allow-toggle"
                                    >
                                        {t("Allowed:")}
                                    </label>
                                    <input
                                        id="allow-toggle"
                                        type="checkbox"
                                        className="w-5 h-5 accent-blue-500 border-gray-300 rounded-md focus:ring-blue-500 focus:ring-2"
                                        checked={limitValue}
                                        onChange={toggleAllowed}

                                    />
                                    {
                                        limitUpdated === true && <span className="text-[red]">Limit updated</span>
                                    }

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(ShiftDetails);
