import * as Yup from "yup";
import { useFormik } from "formik";
import errorIcon from "../../assets/errorIcon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import view from "../../assets/view.png";
import hide from "../../assets/hide.png";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import fetchWithToken from "../../utils/api";
import { setToken, updateUserProfile } from "../../utils/token";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup?.object({
      email: Yup?.string()
        // .email(t('EmailInvalid'))  // Translated invalid email message
        .required(t('EmailOrUsernameRequired')),  // Translated required email message
      password: Yup.string()
        .required(t('PasswordRequired')),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await fetchWithToken("/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...values }),
        });
        const { token, profile } = await response;
        setToken(token);
        updateUserProfile(profile);
        navigate("/dashboard");
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        formik.setErrors({
          password:
            error?.message ||
            t("ErrorMessage"),
        });
      }
    },
  });

  useEffect(() => {
    if (Cookies.get("session")) navigate(`/dashboard`);
  }, [Cookies.get("session")]);

  return (
    <main className="flex flex-1 overflow-hidden h-[100vh] min-h-[100vh] max-h-[100vh] w-full items-center justify-around bg-[#F7F8F7]">
      <div className="w-[40%] h-full relative flex items-center justify-center">
        <img
          src={require("../../assets/logo.png")}
          alt="logo"
          className="w-[75px] absolute top-[35px] left-[20px] sm:flex hidden"
        />
        <form className="w-[310px]" onSubmit={formik?.handleSubmit}>
          <div className="sm:hidden flex justify-center h-[100px]">
            {/* <Image src={logo} alt="logo" className="w-[100px] " /> */}
          </div>
          <div className="pb-0 pt-1 w-full">
            <div className="text-xl pb-2 font-bold  sm:flex hidden">{t("Login")}</div>
            <div className="text-xl pb-7 font-bold mt-1 sm:hidden">{t("Login")}</div>
          </div>
          <div className="font-bold text-sm mb-2">{t("EmailOrUsername")}</div>
          <input
            // type="input"
            placeholder={t("EnterEmailOrUsername")}
            name="email"
            className="w-[280px] h-[35px] text-[#101010] border placeholder-[#5D6561] rounded-lg bg-[#e8f0fe] p-2 text-xs outline-none"
            id="email"
            onChange={(event) => {
              formik.handleChange(event);
            }}
            onBlur={formik?.handleBlur}
            value={formik?.values?.email}
            style={{
              borderColor:
                formik?.touched?.email && formik?.errors?.email
                  ? "#E23121"
                  : "#5D6561",
            }}
          />
          <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
            {formik?.touched?.email && formik?.errors?.email && (
              <div>{formik?.errors?.email}</div>
            )}
          </div>
          <div className="font-bold text-sm mb-2">{t("Password")}</div>
          <div
            className="w-[280px] h-[35px] border rounded-[8px] border-[#5D6561] flex bg-[#e8f0fe]"
            style={{
              borderColor:
                formik?.touched?.password && formik?.errors?.password
                  ? "#E23121"
                  : "#5D6561",
            }}
          >
            <input
              type={visible ? "text" : "password"}
              placeholder={t("EnterPassword")}
              className="w-[245px] text-[#101010] bg-[inherit] placeholder-[#5D6561] rounded-[8px] p-2 text-xs outline-none"
              id="password"
              name="password"
              onChange={(event) => {
                formik.handleChange(event);
              }}
              onBlur={formik?.handleBlur}
              value={formik?.values?.password}
            />
            {formik.errors.password ? (
              <img
                src={require("../../assets/errorIcon.svg")}
                alt="error"
                onClick={() => setVisible(!visible)}
                className="w-4 h-4 mt-[9px]"
              />
            ) : visible ? (
              <img
                src={require("../../assets/hide.png")}
                alt="hide"
                onClick={() => setVisible(!visible)}
                className="w-5 h-5 mt-[6px] cursor-pointer"
              />
            ) : (
              <img
                src={require("../../assets/view.png")}
                alt="view"
                onClick={() => setVisible(!visible)}
                className="w-5 h-5 mt-[6px] cursor-pointer"
              />
            )}
          </div>
          <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
            {formik?.touched?.password && formik?.errors?.password && (
              <div> {t("ErrorMessage")}</div>
            )}
          </div>
          {/* <div className="max-w-[280px]">
            <AnimatedBtn txt="Log In" status={status} setStatus={setStatus} />
          </div> */}
          <button
            type="submit"
            className={`w-[280px] rounded-lg bg-[#00A843] hover:bg-[#389365] ${loading ? "bg-[#7CCF93] cursor-not-allowed" : ""
              } text-white p-2 text-sm mt-4 flex items-center justify-center`}
            disabled={loading}
          >
            {loading ? (
              <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              t("Loginbtn")
            )}
          </button>

          <div className="flex mx-[33px] mt-6">
            <div className="text-xs text-[#333937] py-1">
              {t("Rtitle")}
            </div>
            <button
              onClick={() => navigate("/resetPassword")}
              className="text-base text-[#00A843] font-600 pl-1"
            >
              {t("Rbutton")}
            </button>
          </div>
        </form>
      </div>
      <div className="hidden md:flex h-full w-full md:w-[60%] bg-custom-gradient relative">
        <div className="mt-[130px] ml-[15%]">
          <div className="text-[18px] font-[700]">
            {t("Title")}          </div>
          <div className="text-[18px] font-[500]">
            {t("Title2")}
          </div>
        </div>
        <img
          alt="dashboard"
          src={require("../../assets/dashboard.png")}
          className="absolute w-[85%] right-[0px] top-[280px]"
        />
      </div>
    </main>
  );
};
export default Login;
