import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import editIcon from "../../assets/editIcon.png";
import deleteIcon from "../../assets/deleteIcon.png";
import DeleteModal from "../modals/deleteModal";
import Shift from "../modals/shift";
import moment from "moment";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import { useNavigate } from "react-router-dom";

interface Shifts {
  _id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
}

interface ShiftProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Shifts: React.FC<ShiftProps> = ({ isModalVisible, setModalVisible }) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } = usePagination();
  const navigate = useNavigate()
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [shifts, setShifts] = useState<Shifts[]>([]);
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);
  const fetchShifts = async () => {
    try {
      const data = await fetchWithToken(`/shifts?page=${currentPage}`, {
        method: "GET",
      });
      setShifts(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit)
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
    }
  };

  useEffect(() => {
    fetchShifts();
  }, [currentPage, count]);

  return (
    <>
      <Shift
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchShifts={fetchShifts}
      />
      <DeleteModal
        route="shifts"
        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchShifts}
      />
      <Table className="relative mt-5 w-full">
        <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-[#F7F8F7] w-full">
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("StartTime")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("EndTime")}

                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0">
              <div className="flex items-center">
                {t("ShiftType")}
                <img src={grayArrowDown} alt="" className="w-5 h-5 ml-2" />
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0"></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className="w-full">
          {Array.isArray(shifts) && shifts?.map((shift) => {
            const { _id, start_time, end_time, shift_type } = shift;
            return (
              <TableRow
                key={_id}
                className="cursor-pointer hover:bg-slate-100 lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
              >
                <TableCell onClick={() => navigate(`/shift/${_id}`)}
                  className="lg:hidden font-bold bg-gray-100">{t("StartTime")}</TableCell>
                <TableCell onClick={() => navigate(`/shift/${_id}`)} >
                  {moment(start_time, "HH:mm:ss.SSS").format("h:mm:ss A")}
                </TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">{t("EndTime")}</TableCell>
                <TableCell onClick={() => navigate(`/shift/${_id}`)} >
                  {moment(end_time, "HH:mm:ss.SSS").format("h:mm:ss A")}
                </TableCell>

                <TableCell className="lg:hidden font-bold bg-gray-100">{t("ShiftType")}</TableCell>
                <TableCell onClick={() => navigate(`/shift/${_id}`)} >
                  {shift_type}
                </TableCell>

                <TableCell className="flex justify-end w-full">
                  <div
                    onClick={() => setModalVisible(_id)}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                  </div>
                  <div
                    onClick={() => setDeleteRequestModal(_id)}
                    className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                  >
                    <img
                      alt="deleteIcon"
                      src={deleteIcon}
                      className="w-6 h-6"
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default Shifts;
